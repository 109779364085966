.navbar-container {
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50px;
  top: 0;
  background-color: black;
  transition: transform 0.3s ease-out;
}

.option {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: all 0.3s ease;
  cursor: pointer;
  margin: 5px 0;
  font-size: large;
}

.option:hover {
  font-size: x-large;
  color: #ffd580;
}

.option.selected {
  font-weight: bold;
  font-size: xx-large;
  color: orange;
}

.toggle-btn {
  display: none;
  position: fixed;
  left: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: orange;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 200;
  flex-direction: column;
  justify-content: space-between;
}

.toggle-btn .line {
  width: 100%;
  height: 3px;
  background-color: orange;
}

.navbar-container.open + .toggle-btn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .navbar-container {
    transform: translateX(-210px);
    z-index: 101;
    left: 0;
  }

  .option.selected {
    font-size: x-large;
  }

  .navbar-container.open {
    transform: translateX(0);
  }

  .toggle-btn {
    display: flex;
  }

  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 200px;
    width: calc(100vw - 200px);
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }

  .overlay.show {
    display: block;
  }
}
