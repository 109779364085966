.skills-container {
  padding: 20px 0;
}

.skills-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.skill-category {
  flex: 1;
  min-width: 200px;
}

.skill-category h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.skill-category ul {
  list-style-type: none;
  padding-left: 0;
}

.skill-category li {
  margin-bottom: 8px;
}

.personal-info {
  flex: 2;
}
