.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.about-text {
  text-align: center;
  order: 2;
}

.about-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
  order: 1;
}

.badge-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  order: 3;
}

.badge {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s;
}

.badge:hover {
  transform: scale(1.1);
}

.about-image-and-badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

@media (min-width: 768px) {
  .about-image-and-badge-container {
    flex-direction: row;
    justify-content: center;
    gap: 75px;
  }

  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-text {
    order: 1;
    text-align: left;
    margin-bottom: 40px;
  }

  .about-image {
    order: 2;
  }

  .badge-container {
    order: 3;
  }
}
