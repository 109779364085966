.icon-carousel {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-top: 30px;
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
}

.carousel-slider {
  display: flex;
  transition: all 0.3s ease;
}

.carousel-slide {
  min-width: calc(100% / 3);
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide img {
  width: 25%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Mobile styles */
@media only screen and (max-width: 768px) {
  .carousel-slide img {
    width: 90%;
  }

  .icon-carousel {
    overflow-x: hidden;
    mask-image: linear-gradient(
      to right,
      transparent 5%,
      black 20%,
      black 80%,
      transparent 95%
    );
    -webkit-mask-image: linear-gradient(
      to right,
      transparent 5%,
      black 20%,
      black 80%,
      transparent 95%
    );
  }
}
