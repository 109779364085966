.section-container {
  flex: 1;
  padding: 40px;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 200px;
}

.section-content {
  width: 100%;
  max-width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

@media only screen and (min-width: 769px) {
  .section-content {
    margin-left: 0;
  }
}

/* Mobile styles */
@media only screen and (max-width: 768px) {
  .section-container {
    padding: 10px 20px;
    margin-left: 0;
    align-items: flex-start;
  }

  .section-content {
    margin-left: 0;
    max-width: 100%;
    align-items: flex-start;
  }
}
